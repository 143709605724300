import styled from 'styled-components';

export const CookiesViewWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;
  h2, h3, a {
    color: ${props => `${props.theme.color.primaryColor}`};
  }
  .bold {
    font-weight: bold;
  }
`;
